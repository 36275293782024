<template lang="pug">
.border.rounded-lg.p-4.relative.flex.flex-col.items-center.justify-center(class='min-h-[200px] w-full mx-2')
  // Back
  Icon.w-6.h-6.top-2.left-2.absolute.cursor-pointer(name='material-symbols:arrow-back' style='color: #a3a3a3' @click='$emit("back")')

  // Content
  .flex.flex-col.gap-2.w-full.mt-6
    label.btn-confirm.text-sm.font-medium.required-input(for='content_custom_input' v-if='label') {{ label }}
    Textarea#confirm.text-sm.transition-all.custom-shadow.resize-none(
      v-model='content'
      name='content_custom_input'
      :placeholder='placeholder'
      class='focus-visible:ring-0 focus-visible:ring-offset-0'
    )
    UButton.text-white.gap-2.w-max.border.ml-auto(
        class=''
        @click='$emit("confirm")'
        :disabled='!saveAble'
      )
        .font-semibold Save button
</template>

<script setup lang="ts">
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label'
const UButton = defineAsyncComponent(
  () => import('@/components/ui/button/Button.vue'),
)

const props = defineProps<{
  btn: IButtonAction | undefined
}>()

const emit = defineEmits<{
  (e: 'back'): void
  (e: 'confirm'): void
}>()

const { color } = useBrandColor()

const content = ref('')
const label = computed(() => props.btn?.custom_input?.label || '')
const placeholder = computed(() => props.btn?.custom_input?.placeholder || '')
const saveAble = computed(() => !!content.value)
</script>

<style scoped lang="stylus">
.custom-shadow:focus-visible
  box-shadow inset 0 0 0 1px v-bind(color)
.required-input:after {
  content:" *";
  color: red;
}
</style>
